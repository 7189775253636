export const homeObjOne = {
  id: 'about',
  lightBg: false,
  lightText: true,
  lighttextDesc: true,
  headline: 'Contact Us',
  description: `You can contact us linkedIn, WhatsApp and Instagram for more details`,
  buttonLabel: 'Inquire',
  imgStart: false,
  img: '../../images/svg-1.svg',
  alt: 'about',
  dark: true,
  primary: true,
  darkText: false
};

export const homeObjTwo = {
  id: 'services',
  lightBg: true,
  lightText: false,
  lighttextDesc: false,
  headline: 'Tired of waiting for your EV to charge up?',
  description: `We have a 50kw fast charger which can charge the EV in a jiffy.`,
  buttonLabel: 'Check out our app!',
  imgStart: true,
  img: '/images/svg-2.svg',
  alt: 'app',
  dark: false,
  primary: false,
  darkText: true
};

export const homeObjThree = {
  id: 'demo',
  lightBg: true,
  lightText: false,
  lighttextDesc: false,
  headline: 'Demo',
  description: `Here's a demo of how our charger works.`,
  // buttonLabel: 'Start Now',
  imgStart: false,
  img: '/images/svg-3.svg',
  alt: 'car',
  primary: false,
  darkText: true
};
