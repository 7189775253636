import React, {useState} from 'react'
// eslint-disable-next-line
import { HeroContainer, HeroBg, HeroContent, HeroH1, HeroP, HeroBtnWrapper, ArrowForward, ArrowRight, ImgBg } from './HeroElements'
// eslint-disable-next-line
import { Button } from '../ButtonElements'

const Hero = () => {
  const [hover, setHover] = useState(false);
// eslint-disable-next-line
  const onHover = () => {
    setHover(!hover)
  }

  return (
    <HeroContainer id='home'>
      <HeroBg>
        <ImgBg src='../../images/electric_car_green_environment.svg'>
        </ImgBg>
      </HeroBg>
      <HeroContent>
        <HeroH1>Bella Charge</HeroH1>
        <HeroP>Charging made simple, fast and effective!</HeroP>
        <HeroBtnWrapper>
          {/* <Button to='/inquire' onMouseEnter={onHover} onMouseLeave={onHover} primary='true' dark='true' smooth={true} duration={500} spy={true} exact='true' offset={-80}>
            Book Now {hover ? <ArrowForward /> : <ArrowRight/>}
          </Button> */}
        </HeroBtnWrapper>
      </HeroContent>
    </HeroContainer>
  )
}

export default Hero
