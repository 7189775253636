import React from 'react'
// eslint-disable-next-line
import Icon1 from '../../images/svg-1.svg'
// eslint-disable-next-line
import Icon2 from '../../images/svg-2.svg'
// eslint-disable-next-line
import Icon3 from '../../images/svg-3.svg'
// eslint-disable-next-line
import {
  ProductsContainer,
  ProductsH1,
  ProductsWrapper,
  ProductsCard,
  ProductsH2,
  ProductsP,
  ProductsP1
} from './ProductElements'

const Products = () => {
  return (
    <ProductsContainer id='products'>
      <ProductsH1>Our Product</ProductsH1>
      <ProductsP1>
        Our aim is to provide the best quality and easy to use product to our
        customers so that they can have a hassle free experience while charging
        their EV.
      </ProductsP1>
      <ProductsWrapper>
        <ProductsCard>
          {/* <ProductsIcon src={Icon1}/> */}
          <ProductsH2>Multi Purpose</ProductsH2>
          <ProductsP>
            Charge any Electric Vehicle, from two wheelers to four wheelers.
          </ProductsP>
        </ProductsCard>
        <ProductsCard>
          {/* <ProductsIcon src={Icon2}/> */}
          <ProductsH2>No more queue</ProductsH2>
          <ProductsP>
            Charge 4 electric vehicles at a time from a single charger without hassle.
          </ProductsP>
        </ProductsCard>
        <ProductsCard>
          {/* <ProductsIcon src={Icon3}/> */}
          <ProductsH2>Safe and reliable charging</ProductsH2>
          <ProductsP>
            All safety standards have been adhered to for user safety.
          </ProductsP>
        </ProductsCard>
        <ProductsCard>
          {/* <ProductsIcon src={Icon3}/> */}
          <ProductsH2>Travel anywhere!</ProductsH2>
          <ProductsP>
            Charge anytime and anywhere with a portable and affordable fast
            charger.
          </ProductsP>
        </ProductsCard>
        <ProductsCard>
          {/* <ProductsIcon src={Icon3}/> */}
          <ProductsH2>Easy set-up</ProductsH2>
          <ProductsP>
            Installation and best quality maintenance at no additional cost.
          </ProductsP>
        </ProductsCard>
        <ProductsCard>
          {/* <ProductsIcon src={Icon3}/> */}
          <ProductsH2>An easier UI</ProductsH2>
          <ProductsP>
            Control all the functions of the charger with a single touch on your phone.
          </ProductsP>
        </ProductsCard>
      </ProductsWrapper>
    </ProductsContainer>
  )
}

export default Products
