import React from 'react'
import ScrollToTop from '../components/ScrollToTop'
import Inquire from '../Inquire'

const InquirePage = () => {
  return (
    <>
    <ScrollToTop />
      <Inquire />
    </>
  )
}

export default InquirePage
