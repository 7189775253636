import React, { useRef } from 'react'
import emailjs from '@emailjs/browser'
import {
  Container,
  FormWrap,
  Icon,
  FormContent,
  Form,
  FormInput,
  FormH1,
  FormLabel,
  FormButton
} from './InquireElements';
import { useState } from 'react';

const Result = ()=>{
  return(
    <>
    <p>Your inquiry form has been successfully submitted!</p>
    </>
  )
}

export const Inquire = () => {
  const form = useRef();

  const [result, setResult] = useState(false)

  const sendEmail = (e) => {
    e.preventDefault()

    emailjs
      .sendForm(
        'service_uwtlglj',
        'template_afizoaf',
        form.current,
        'Q5ZaQCZpWqVhnXaQp'
      )
      .then(
        result => {
          console.log(result.text)
        },
        error => {
          console.log(error.text)
        }
      );
      e.target.reset();
      setResult(true);
  }

  setTimeout(() => {
    setResult(false)
  }, 3000);
  
  return (
    <>
      <Container>
        <FormWrap>
          <Icon to='/'>Bella Charge</Icon>
          <FormContent>
            <Form action='/' ref={form} onSubmit={sendEmail}>
              <FormH1>Get a quote</FormH1>
              <FormLabel htmlFor='for'>Name</FormLabel>
              <FormInput typeof='text' htmlFor='name' name='name' id='name' required />
              <FormLabel typeof='text' htmlFor='for'>Email</FormLabel>
              <FormInput typeof='text' htmlFor='email' name='email' id='email' required />
              <FormLabel typeof='text' htmlFor='for'>Message</FormLabel>
              <FormInput typeof='text' htmlFor='message' name='message' id='message' required />
              <FormButton type='submit'>Submit</FormButton>
              <div className="row" style={{color:'white', margin:'15px 0px 0px 0px'}}>
                {
                  result ? <Result /> : null
                }
              </div>
            </Form>
          </FormContent>
        </FormWrap>
      </Container>
    </>
  )
}

export default Inquire